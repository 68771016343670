<template>
  <div class="twitter-profile-image">
    <img :src="fixedUrl" alt="Twitter profile image">
    <template v-if="!!handle && handle.length > 0">
      <span id="handle">
        @{{handle}}
      </span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'TwitterProfileImage',

  props: ['url', 'handle'],

  computed: {
    fixedUrl () {
      const splitIdx = this.url.lastIndexOf('/')
      const path = this.url.substring(0, splitIdx)
      const image = this.url.substring(splitIdx + 1)
        .replace('_normal.', '.')
        .replace('_bigger.', '.')
        .replace('_mini.', '.')

      return `${path}/${image}`
    }
  }
}
</script>

<style lang="scss" scoped>
  .twitter-profile-image {
    margin-top: 10px;
    max-width: 500px;
    display: flex;
    align-items: center;

    img {
      width: 124px;
      border-radius: 50%;
      margin-right: 20px;
    }

    @media only screen and (max-width: 767px) {
      margin-left: auto;
      margin-right: auto;
    }

    @media only screen and (max-width: 1023px) {
      img {
        width: 64px;
      }
    }
  }

</style>
