import { pathOr, pipe, defaultTo, always, ifElse, converge, call, identity, curryN } from 'ramda'

/**
 * Returns a function that calls `pathOr` for one of the two paths
 * depending on the result from `checkFunction`
 * If `checkFn` returns true, it uses `alternativePath`
 * else it uses `normalPath`
 *
 * @callback PathOrIf
 * @param {function(): boolean} useAlternative a function that determines whether we should use alternative path or normal path
 * @param {*} defaultValue a value to return if the chosen path is not reachable or undefined
 * @param {string[]} normalPath the path chosen if `checkFn` returns false
 * @param {string[]} alternativePath the path chosen if `checkFn` returns true
 * @returns {*} value found at chosen path
 */

/** @type {PathOrIf} PathOrIf */
export const pathOrIf = curryN(4, (useAlternative, defaultValue, normalPath, alternativePath) => {
  const getPath = ifElse(useAlternative, always(alternativePath), always(normalPath))
  const getValueFromPath = pipe(defaultTo({}), getPath, pathOr(defaultValue))
  return converge(call, [getValueFromPath, identity])
})
