<template>
    <div class="moderate-instagram-interaction">
      <el-row :gutter="4">
        <el-col :xs="24">
          <template v-if="instagramInteraction">
            <el-row :gutter="10">
              <el-col :xs="24" :sm="15">
                <h3 class="underline mb-0">
                  <span>User Information:</span>
                  <span class="text-green bold" style="margin-left: 5px;">{{ instagramDisplayName }}</span>
                </h3>
                <el-row>
                  <el-col class="left-grid">
                    <div class="profile-photo">
                      <span>
                        <h4>Instagram Profile Picture:</h4>
                      </span>
                      <div style="display: flex; flex-flow: wrap;">
                        <img
                          :src="instagramProfilePicture"
                          :width="200"
                          :height="150"
                        />
                        <i
                          class="el-icon-view"
                          style="position: absolute; right: 5px; top: 5px; padding: 5px; border-radius: 50%; background: #7fe0c4;"
                        />
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </el-col>
  
              <el-col :xs="24" :sm="9">
                <h3 class="underline mb-0">
                  Engagement:
                  <span class="text-green bold">{{ engagementType }}</span>
                </h3>
                <div class="right-grid">
                  <div>
                    <h4>Copy:</h4>
                    <p>{{ instagramInteractionCopy }}</p>
                  </div>
                </div>
              </el-col>
            </el-row>
  
            <el-row class="full-width footer" :gutter="20">
              <el-col
                :xs="12"
                :sm="{ span: 14 }"
                v-if="recommendationOutcome === 'approve' || recommendationOutcome === 'reject'"
              >
                <h4 class="underline mb-10 mt-10">
                  <span data-testid="recommendation-label">Recommendation:</span>
                  <span
                    v-bind:class="{ 'text-green': recommendationOutcome === 'approve', 'text-coral': recommendationOutcome === 'reject' }"
                    style="margin-left: 5px; text-transform: capitalize;"
                    data-testid="recommendation-result"
                  >{{recommendationOutcome}}</span>
                </h4>
  
                <span
                  data-testid="recommendation-copy"
                  v-if="recommendationOutcome === 'approve'"
                >Based on previously moderated engagements of similar nature and intent, this item meets the requirements necessary for approval.</span>
                <span
                  data-testid="recommendation-copy"
                  v-if="recommendationOutcome === 'reject'"
                >Based on previously moderated engagements of similar nature and intent, this item does not meet the requirements for approval.</span>
              </el-col>
  
              <el-col :xs="12" :sm="{ span: 14 }" v-if="recommendationOutcome === 'unsure'">
                <!-- we don't currently show anything on 'unsure' -->
              </el-col>
  
              <!-- Approve Reject buttons -->
              <el-col
                :xs="12"
                :sm="{span: 4, offset: (!recommendationOutcome || recommendationOutcome === 'unsure') ? 16 : 2}"
                class="mt-20 mb-20"
              >
                <el-button
                  class="action-btn approve-btn"
                  round
                  @click="onApproveClicked"
                  :loading="displayLoading"
                  type="success"
                >Approve</el-button>
              </el-col>
              <el-col :xs="12" :sm="4" class="mt-20 mb-20">
                <el-button
                  class="action-btn decline-btn"
                  round
                  @click="onDeclineClicked"
                  :loading="displayLoading"
                  type="danger"
                >Reject</el-button>
              </el-col>
            </el-row>
          </template>
          <template v-else>
            <div class="text--center" style="padding-bottom: 20px;">Loading Instagram Interaction...</div>
          </template>
        </el-col>
      </el-row>
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex'
  import * as R from 'ramda'
  
  const getEngagementType = R.cond([
    [R.has('messageEvent'), R.always('Instagram Story Reply')],
    [R.T, R.always('Comment on Post')]
  ])
  const getInstagramInteractionCopy = R.cond([
    [R.has('messageEvent'), R.path(['messageEvent', 'message', 'text'])],
    [R.T, R.pathOr('', ['commentEvent', 'changes', 0, 'value', 'text'])]
  ])
  const getInstagramDisplayName = R.cond([
    [R.has('messageEvent'), R.path(['messageEvent', 'sender', 'name'])],
    [R.T, R.pathOr('', ['commentEvent', 'changes', 0, 'value', 'from', 'username'])]
  ])
  const getInstagramProfilePic = R.cond([
    [R.has('messageEvent'), R.path(['messageEvent', 'sender', 'profile_pic'])],
    [R.T, R.pathOr('', ['commentEvent', 'changes', 0, 'value', 'from', 'profile_pic'])]
  ])
  
  export default {
    name: 'ModerateinstagramInteraction',  
    props: ['id', 'instagramInteraction'],
  
    data () {
      return {
        loading: false,
        dbRef: null,
        unsubscribeFromDocUpdates: null,
        moderation: { data: { tweet: {} } },
        selectedMedia: null,
        mediaSelected: false
      }
    },
  
    computed: {
      ...mapGetters(['isAuthenticated', 'moderatorId']),
  
      
  
      instagramProfilePicture () {
        return getInstagramProfilePic(this.moderation?.data?.instagramActivity)
      },

      engagementType () {
        return getEngagementType(this.moderation?.data?.instagramActivity)
      },
  
      instagramInteractionCopy () {
        return getInstagramInteractionCopy(this.moderation?.data?.instagramActivity)
      },

      instagramDisplayName () {
        return getInstagramDisplayName(this.moderation?.data?.instagramActivity)
      },
  
      recommendationOutcome () {
        const { recommendation } = this.moderation.data
        if (!recommendation) return 'unsure'
        const { outcome } = recommendation
        if (!outcome) return 'unsure'
        return outcome
      },
  
      displayLoading () {
        return this.loading || !this.moderation || !this.moderatorId
      }
    },
  
    mounted () {
      this.loadModeration()
  
      /* istanbul ignore next */
      window.addEventListener('beforeunload', () => {
        this.stopListeningForDocChanges()
      })
    },
  
    // istanbul ignore next
    beforeDestroy () {
      this.stopListeningForDocChanges()
    },
  
    watch: {
      id: function () {
        this.loadModeration()
      }
    },
  
    methods: {
      loadModeration () {
        if (!this.isAuthenticated) return
  
        if (this.instagramInteraction) {
          this.moderation = this.instagramInteraction
          this.loading = false
        }
  
        this.listenForDocChanges()
      },
  
      listenForDocChanges () {
        // onSnapshot will return a unsubscribe function
        // https://firebase.google.com/docs/firestore/query-data/listen#detach_a_listener
        this.unsubscribeFromDocUpdates = this.$firestore
          .collection(process.env.VUE_APP_FIREBASE_COLLECTION)
          .doc(this.id)
          .onSnapshot(this.onDocSnapshot)
      },
  
      stopListeningForDocChanges () {
        // istanbul ignore next
        if (this.unsubscribeFromDocUpdates) this.unsubscribeFromDocUpdates()
      },
  
      onDocSnapshot (docSnapshot) {
        if (!docSnapshot.exists) return
        const data = docSnapshot.data()
  
        this.moderation = {
          ...this.moderation,
          ...data
        }
        this.dbRef = docSnapshot.ref
        this.loading = false
  
        const { status } = data
  
        if (this.docId === docSnapshot.id && status !== 'pending') {
          // approved or declined in another browser session, so we must forget about moderating it
          const statusDisplay = status === 'declined' ? 'rejected' : status
  
          this.$notify({
            type: 'warning',
            title: 'Notice',
            message: `This item was just ${statusDisplay} by another moderator`
          })
          this.$emit('update-status')
        }
      },
  
      updateModeration (
        // istanbul ignore next
        { cb = async tx => tx.update(this.dbRef, this.moderation), store = this.$firestore } = {}
      ) {
        if (!this.isAuthenticated) return
        if (!this.moderatorId) { throw new Error('Moderator id is not set') }
        return store.runTransaction(cb)
      },
  
      async onApproveClicked () {
        this.loading = true
        this.moderation.status = 'approved'
        this.moderation.locked = false
        this.moderation.lockedAt = this.$firebase.firestore.FieldValue.delete()
        this.moderation.moderatorId = this.moderatorId
  
        try {
          await this.updateModeration()
        } catch (e) {
          this.$notify({
            type: 'error',
            title: 'Approve failed',
            message: 'Failed to approve item. \n Please try clearing your browser cache. If the problem persists, please contact Blue Robot customer success for assistance.'
          })
          console.error(`Failed to approve item with id "${this.id}": ${e.message}`)
        }
  
        this.stopListeningForDocChanges()
        this.$emit('update-status')
      },
  
      async onDeclineClicked () {
        this.loading = true
        this.moderation.status = 'declined'
        this.moderation.locked = false
        this.moderation.lockedAt = this.$firebase.firestore.FieldValue.delete()
        this.moderation.moderatorId = this.moderatorId
  
        try {
          await this.updateModeration()
        } catch (e) {
          this.$notify({
            type: 'error',
            title: 'Decline failed',
            message: 'Failed to decline item. \n Please try clearing your browser cache. If the problem persists, please contact Blue Robot customer success for assistance.'
          })
          console.error(`Failed to decline item with id "${this.id}": ${e.message}`)
        }
  
        this.stopListeningForDocChanges()
        this.$emit('update-status')
      },
  
      handleMediaDialogClicked (e) {
        e.stopPropagation()
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  @import '@/styles/colours.scss';
  
  .moderate-instagram-interaction a {
    text-decoration: none;
  }
  
  .action-btn {
    width: 100%;
    border: none;
  }
  
  .approve-btn {
    background-color: rgb(127, 224, 196) !important;
  }
  
  .decline-btn {
    background-color: rgb(229, 60, 99) !important;
  }
  
  .footer {
    background: rgb(57, 63, 94);
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  
    button {
      padding: 20px;
      border-radius: 50px;
    }
  }
  
  .full-width {
    width: calc(100% + 40px);
    margin-left: -20px !important;
    padding-right: 20px;
    padding-left: 20px;
  }
  
  .underline {
    border-bottom: 1px solid;
    padding-bottom: 10px;
  }
  
  .text-green {
    color: rgb(127, 224, 196);
  }
  
  .text-coral {
    color: rgb(229, 60, 99);
  }
  
  .bold {
    font-weight: bold;
  }
  
  .media-dialog {
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background: rgba(30, 40, 71, 0.7);
  
    div {
      display: flex;
      flex-direction: column;
  
      .close-button {
        align-self: flex-end;
        font-size: 3em;
        cursor: pointer;
      }
  
      img,
      video {
        width: auto;
        height: auto;
        max-width: 80vw;
        max-height: 80vh;
        border-radius: 15px;
      }
    }
  }
  
  $row-height-md: 220px;
  $row-height-sm: 150px;
  $row-height-xs: 100px;
  
  .left-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    grid-auto-rows: minmax($row-height-md, auto);
  
    @media (max-width: 768px) {
      grid-auto-rows: minmax($row-height-xs, auto);
  
      .profile-description,
      .profile-location {
        grid-column: span 2;
      }
  
      .profile-background {
        grid-row: 1;
        grid-column: 2;
      }
  
      .profile-photo {
        .twitter-profile-image {
          justify-content: center;
        }
      }
    }
  
    @media (min-width: 768px) and (max-width: 1024px) {
      grid-auto-rows: minmax($row-height-sm, auto);
  
      .profile-background {
        grid-row: 1;
        grid-column: 2;
      }
  
      .profile-description,
      .profile-location {
        grid-column: span 2;
      }
    }
  }
  
  .right-grid {
    display: grid;
    grid-gap: 10px;
    grid-auto-rows: minmax($row-height-md, auto);
    grid-auto-columns: 100%;
  
    @media (max-width: 768px) {
      grid-auto-rows: minmax($row-height-xs, auto);
    }
  
    @media (min-width: 768px) and (max-width: 1024px) {
      grid-auto-rows: minmax($row-height-sm, auto);
    }
  }
  </style>
  
  <style lang="scss">
  .left-grid {
    @media (max-width: 768px) {
      .profile-photo {
        .twitter-profile-image {
          img {
            width: 100px;
          }
        }
      }
    }
  }
  </style>
  