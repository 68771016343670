<template>
  <div id="moderate-experience">
    <el-row type="flex" justify="space-between">
      <el-col :xs="6" :sm="8" :md="6">
        <el-button round @click="navigateToExperiences" style="width: 100%;" class="hidden-xs-only">
          <i class="fa fa-chevron-left" style="margin-right: 10px"></i>
          Back to experiences
        </el-button>
        <el-button type="text" @click="navigateToExperiences" class="hidden-sm-and-up">
          <i class="fa fa-chevron-left" style="margin-right: 10px"></i>
        </el-button>
      </el-col>
      <el-col :sm="6" :xs="24">
        <el-button round type="success" style="width: 100%;" @click="navigateToAudit">View log</el-button>
      </el-col>
    </el-row>
    <el-row :gutter="4" class="mt-20 mb-40">
      <el-col :span="24">
        <div class="experiences-card">
          <el-card shadow="always" class="mb-10" :bodyStyle="{ padding: '10px' }">
            <ModerateExperienceInfo :experience="experience" />
          </el-card>
        </div>
      </el-col>

      <el-col :span="24">
        <el-card shadow="never" class="mb-10" v-bind:class="{ 'no-bottom-padding': hasModeration }">
          <template v-if="!hasModeration">
            There is currently nothing to moderate for this experience.
            <br />Waiting...
          </template>

          <template v-if="platform === 'twitter'">
            <ModerateTweet :id="this.docId" @update-status="loadNext()" :tweet="userActivity" />
          </template>
          <template v-if="platform === 'facebook'">
            <ModerateFacebookComment :id="this.docId" @update-status="loadNext()" :facebookComment="userActivity" />
          </template>
          <template v-if="platform === 'instagram'">
            <ModerateInstagramInteraction :id="this.docId" @update-status="loadNext()" :instagramInteraction="userActivity" />
          </template>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ModerateExperienceInfo from '@/components/experiences/ModerateExperienceInfo.vue'
import ModerateTweet from '@/components/moderation/ModerateTweet.vue'
import ModerateFacebookComment from '@/components/moderation/ModerateFacebookComment.vue'
import ModerateInstagramInteraction from '@/components/moderation/ModerateInstagramInteraction.vue'
import { mapGetters, mapActions } from 'vuex'
import * as R from 'ramda'

export default {
  name: 'ModerateExperience',

  props: ['id'],

  components: {
    ModerateExperienceInfo,
    ModerateTweet,
    ModerateFacebookComment,
    ModerateInstagramInteraction
},

  data () {
    return {
      loading: false,
      dbRef: null,
      activityDoc: null,
      userActivity: null,
      experience: { id: ' ' },
      moderations: [],
      docId: ' ',
      locked: false,
      platform: ''
    }
  },

  computed: {
    ...mapGetters(['isAuthenticated', 'firebaseToken', 'moderatorId']),

    hasModeration () {
      return this.moderations && this.moderations.length > 0
    }
  },

  async beforeDestroy () {
    await this.unloader()
  },

  async mounted () {
    await this.loadExperience()

    this.$firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        this.idToken = await user.getIdToken()
      }
    })

    /* istanbul ignore next */
    window.addEventListener('beforeunload', e => {
      this.unloader()
    })
  },

  methods: {
    ...mapActions(['validateToken']),

    unloader (event) {
      this.unlock().catch(e => console.error(`Error unlocking document: ${this.docId}`, e))
      this.stopListeningForAllChanges()
    },

    async loadNext () {
      await this.unlockCurrentUserActivity(false)

      const nextRef = await this.$firestore
        .collection(process.env.VUE_APP_FIREBASE_COLLECTION)
        .where('widgetId', '==', this.$route.params.id)
        .where('status', '==', 'pending')
        .where('locked', '==', false)
        .limit(1)

      const activityDoc = await nextRef.get()
      if (activityDoc && activityDoc.exists) {
        await this.onSnapshot(activityDoc)
      } else {
        await this.listenForAllChanges()
      }
    },

    async getExperienceFromBackend () {
      const response = await this.$ky.get(`${process.env.VUE_APP_API_URL}/experiences/${this.$route.params.id}`)

      if (!response) {
        this.$notify({
          type: 'error',
          title: 'A network error occured',
          message: 'Please refresh your page.',
          duration: 0
        })
        return
      }
      if (response.status === 200) {
        this.experience = response.data.data
      }

      if (response.status === 403) {
        this.$notify({
          type: 'error',
          title: 'Not Authorized',
          message: 'You are not authorized to access this page.'
        })
        this.experience = {}
        this.validateToken()
      }
    },

    async loadExperience () {
      if (!this.isAuthenticated) return

      this.loading = true

      try {
        await this.getExperienceFromBackend()
      } finally {
        this.loading = false

        await this.listenForAllChanges()
      }
    },

    async onSnapshot (querySnapshot = { docs: [] }) {
      if (this.listening) {
        const moderations = []
        for (let i = 0; i < querySnapshot.docs.length; i++) {
          const activityDoc = querySnapshot.docs[i]
          let freshActivityDoc

          if (activityDoc.metadata.fromCache) {
            const activityDocRef = await this.$firestore
              .collection(process.env.VUE_APP_FIREBASE_COLLECTION)
              .doc(activityDoc.id)

            freshActivityDoc = await activityDocRef.get()
          } else {
            freshActivityDoc = activityDoc
          }

          if (freshActivityDoc.exists) {
            const userActivity = freshActivityDoc.data()
            const platform = R.cond([
                [R.hasPath(['twitterActivity']), R.always('twitter')],
                [R.hasPath(['facebookActivity']), R.always('facebook')],
                [R.hasPath(['instagramActivity']), R.always('instagram')],
                [R.T, R.always('twitter')]
            ])(userActivity.data)
            this.platform = platform
            this.userActivity = userActivity

            if (userActivity.status === 'pending') {
              moderations.push({ id: freshActivityDoc.id, data: userActivity })
            }
          }
        }

        this.moderations = moderations

        await this.loadNextUserActivity()
      }
    },

    async listenForAllChanges () {
      this.dbRef = await this.$firestore
        .collection(process.env.VUE_APP_FIREBASE_COLLECTION)
        .where('widgetId', '==', this.$route.params.id)
        .where('status', '==', 'pending')
        .where('locked', '==', false)
        .limit(1)
        .onSnapshot(this.onSnapshot)
      this.listening = true
    },

    async stopListeningForAllChanges () {
      this.listening = false
      if (this.dbRef) await this.dbRef()
    },

    async unlockCurrentUserActivity (mustListen = true) {
      this.locked = false
      if (mustListen) await this.listenForAllChanges()
    },

    async lockCurrentUserActivity (nextActivity) {
      const { id } = nextActivity

      this.locked = false

      this.activityDoc = await this.$firestore.collection(process.env.VUE_APP_FIREBASE_COLLECTION).doc(id)

      await this.activityDoc
        .update({
          locked: true,
          lockedAt: this.$firebase.firestore.FieldValue.serverTimestamp(),
          moderatorId: this.moderatorId
        })
        .catch(async err => {
          console.error(`Error locking document: ${id}`, err)
          await this.unlockCurrentUserActivity()
        })

      return nextActivity
    },

    async loadNextUserActivity () {
      if (this.hasModeration) {
        const [nextActivity] = this.moderations

        await this.stopListeningForAllChanges()
        await this.lockCurrentUserActivity(nextActivity)

        this.docId = nextActivity.id
        this.userActivity = nextActivity.data
      }
      else {
        this.platform = ''
      }
    },

    navigateToExperiences () {
      this.$router.push('/experiences')
    },

    navigateToAudit () {
      this.$router.push(`/experiences/${this.$route.params.id}/audit`)
    },

    unlock () {
      const projectId = process.env.VUE_APP_FIREBASE_PROJECT_ID
      const collection = process.env.VUE_APP_FIREBASE_COLLECTION
      const docId = this.docId
      const token = this.idToken

      if (!(docId && token)) return Promise.resolve()

      const firebaseApi = 'https://firestore.googleapis.com/v1/projects'
      const query = 'updateMask.fieldPaths=locked' +
        '&updateMask.fieldPaths=lockedAt' +
        '&updateMask.fieldPaths=moderatorId'
      const url = `${firebaseApi}/${projectId}/databases/(default)/documents/${collection}/${docId}?${query}`

      const params = JSON.stringify({
        fields: {
          locked: {
            booleanValue: false
          },
          lockedAt: {
            nullValue: null
          },
          moderatorId: {
            nullValue: null
          }
        }
      })

      return window.fetch(url, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        keepalive: true,
        body: params
      })
    }
  }
}
</script>

<style lang="scss">
#moderate-experience {
  .el-card__body {
    @media (max-width: 768px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .no-bottom-padding {
    .el-card__body {
      padding-bottom: 0;
    }
  }
}
</style>

<style lang="scss" scoped>
#moderate-experience {
  .el-card {
    background-color: rgba(44, 51, 81, 0.9);
    border: none;
    color: #fff;
  }

  .experiences-card .el-card {
    background-color: rgb(15, 19, 45);
  }
}
</style>
